.sliding-background {
  width: 5076px;
  animation: slide 60s linear 50ms infinite;
}
.sliding-text {
  width: 100vw;
  animation: reverseSlide 60s linear 50ms infinite;
}
@keyframes slide {
  0% {
    transform: skewX(0.1deg) translate3d(0, 0, 0);
    -webkit-transform: skewX(0.1deg) translate3d(0, 0, 0);
    -moz-transform: skewX(0.1deg) translate3d(0, 0, 0);
  }
  100% {
    transform: skewX(0.1deg) translate3d(-2144px, 0, 0);
    -webkit-transform: skewX(0.1deg) translate3d(-2144px, 0, 0);
    -moz-transform: skewX(0.1deg) translate3d(-2144px, 0, 0);
  }
}
@keyframes reverseSlide {
  0% {
    transform: skewX(0.1deg) translate3d(0, 0, 0);
    -webkit-transform: skewX(0.1deg) translate3d(0, 0, 0);
    -moz-transform: skewX(0.1deg) translate3d(0, 0, 0);
  }
  100% {
    transform: skewX(0.1deg) translate3d(2144px, 0, 0);
    -webkit-transform: skewX(0.1deg) translate3d(2144px, 0, 0);
    -moz-transform: skewX(0.1deg) translate3d(2144px, 0, 0);
  }
}
